import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSessionContext } from "../../../hooks";
import {
  RoutesHeaderCard1,
  RoutesHeaderCard2,
  RoutesHeaderCard3,
  RoutesHeaderCard4,
  RoutesHeaderCard5,
  RoutesItemBottleCox,
  RoutesItemCapCox,
  RoutesItemCapKbb,
  RoutesItemCardHolderCase,
  RoutesItemCoolerKombi,
  RoutesItemCupCox,
  RoutesItemMetalPen,
  RoutesItemMoleskineCox,
  RoutesItemSocksDt,
  RoutesItemSocksKbb,
  RoutesItemYellowNotePad,
} from "../../assets";
import { CardRoutesMolecule } from "../../molecules";
import { ICardRouteItem } from "../../molecules/card-routes-program/card-routes-program.molecule";
import {
  CardRoutesFootnote,
  ContentWrapper,
  RoutesProgramCardsWrapper,
  RoutesProgramDescription,
  RoutesProgramDescriptionText,
  RoutesProgramDownloadTerms,
  RoutesProgramHeader,
} from "./routes-program.page.style";
import { useTranslation } from "react-i18next";

export const RoutesProgramPage = () => {
  const { setActualPageContext } = useSessionContext();
  const { t } = useTranslation();

  useEffect(() => {
    setActualPageContext("ROUTEPROGRAM");
    // eslint-disable-next-line
  }, []);

  const itemsStandard: ICardRouteItem[] = [
    {
      image: RoutesItemCoolerKombi,
      imageTitle: "Cooler Kombi",
      cardLabel: "1.800 Pontos",
    },
    {
      image: RoutesItemBottleCox,
      imageTitle: "Squeeze Cox Automotive",
      cardLabel: "1.200 Pontos",
    },
    {
      image: RoutesItemCapCox,
      imageTitle: "Boné Cox Automotive",
      cardLabel: "540 Pontos",
    },
    {
      image: RoutesItemCapKbb,
      imageTitle: "Boné KBB",
      cardLabel: "540 Pontos",
    },
    {
      image: RoutesItemMoleskineCox,
      imageTitle: "Moleskine Cox Automotive",
      cardLabel: "540 Pontos",
    },
    {
      image: RoutesItemSocksKbb,
      imageTitle: "Meia Decorada KBB",
      cardLabel: "400 Pontos",
    },
    {
      image: RoutesItemSocksDt,
      imageTitle: "Meia Decorada Dealertrack",
      cardLabel: "400 Pontos",
    },
    {
      image: RoutesItemCupCox,
      imageTitle: "Copo Fibra de Bambu Cox Automotive",
      cardLabel: "400 Pontos",
    },
    {
      image: RoutesItemYellowNotePad,
      imageTitle: "Bloco de Notas Capa Dura Amarela P",
      cardLabel: "400 Pontos",
    },
    {
      image: RoutesItemMetalPen,
      imageTitle: "Caneta Metal Fosca",
      cardLabel: "140 Pontos",
    },
    {
      image: RoutesItemCardHolderCase,
      imageTitle: "Porta Cartão para Celular",
      cardLabel: "140 Pontos",
    },
  ];

  return (
    <>
      <ContentWrapper>
        <Container>
          <RoutesProgramHeader className="my-5">
            <img src={RoutesHeaderCard1} alt="header-card-1" />
            <img src={RoutesHeaderCard2} alt="header-card-2" />
            <img src={RoutesHeaderCard3} alt="header-card-3" />
            <img src={RoutesHeaderCard4} alt="header-card-4" />
            <img src={RoutesHeaderCard5} alt="header-card-5" />
          </RoutesProgramHeader>

          <RoutesProgramDescription className="my-5">
            <RoutesProgramDescriptionText>
              Para <b>resgatar os prêmios</b>, por favor enviar a solicitação
              para o email{" "}
              <b className="email">programa.rota@coxautomotive.com.br</b>
            </RoutesProgramDescriptionText>
            <RoutesProgramDownloadTerms>
              <a
                href="REGULAMENTO_PROGRAMA_DE_FIDELIDADE_ROTA_Abril_2023.pdf"
                target="_blank"
              >
                {t("ClickToSeeTheRegulation")} <b>{t("RouteProgram")}</b>
              </a>
            </RoutesProgramDownloadTerms>
          </RoutesProgramDescription>

          {/* <RoutesProgramDescriptionText>
            Prêmios Campanha <b>NA ROTA DO GOL</b>
          </RoutesProgramDescriptionText>

          <RoutesProgramCardsWrapper className="my-5">
            {itemsNaRotaDoGol.map((m, i) => (
              <CardRoutesMolecule
                key={i}
                {...m}
                campaignTitle="Campanha na Rota do Gol"
                profile="naRotaDoGol"
                campaignLogo={NaRotaDoGolCampaignLogo}
                campaignLogoWidth="76px"
              />
            ))}
          </RoutesProgramCardsWrapper>

          <CardRoutesDashedHR className="my-5" /> */}

          <RoutesProgramCardsWrapper className="my-5">
            {itemsStandard.map((m, i) => (
              <CardRoutesMolecule
                key={i}
                {...m}
                campaignTitle="Campanha Rota"
              />
            ))}
          </RoutesProgramCardsWrapper>

          <CardRoutesFootnote className="my-5">
            * Fotos dos produtos meramente ilustrativas.
          </CardRoutesFootnote>
        </Container>
      </ContentWrapper>
    </>
  );
};

// const itemsNaRotaDoGol: ICardRouteItem[] = [
//   {
//     image: RoutesItemSamsungChromebook,
//     imageTitle: "Samsung Chromebook 1.1GHz",
//     cardLabel: "12.000 Pontos",
//   },
//   {
//     image: RoutesItemSamsungSmartphone,
//     imageTitle: "Smartphone Samsung Galaxy A32 128GB",
//     cardLabel: "11.000 Pontos",
//   },
//   {
//     image: RoutesItemSamsungTablet,
//     imageTitle: "Tablet Samsung Galaxy A7 Lite",
//     cardLabel: "10.000 Pontos",
//   },
//   {
//     image: RoutesItemBackpackLacoste,
//     imageTitle: "Mochila Lacoste Néocroc",
//     cardLabel: "4.500 Pontos",
//   },
//   {
//     image: RoutesItemSpeakerJblFlip,
//     imageTitle: "Caixa de Som JBL Flip Essential 16W RMS",
//     cardLabel: "4.500 Pontos",
//   },
//   {
//     image: RoutesItemHeadphoneJblBt,
//     imageTitle: "Fone de Ouvido Bluetooth JBL Wave",
//     cardLabel: "3.000 Pontos",
//   },
//   {
//     image: RoutesItemSpeakerJblGo,
//     imageTitle: "Caixa de Som JBL Go 3",
//     cardLabel: "2.500 Pontos",
//   },
//   {
//     image: RoutesItemCupStanley,
//     imageTitle: "Copo de Cerveja Stanley",
//     cardLabel: "2.500 Pontos",
//   },
//   {
//     image: RoutesItemBackpackCox,
//     imageTitle: "Mochila Executiva Cox Automotive",
//     cardLabel: "2.000 Pontos",
//   },
// ];
// {
//   image: RoutesItemWhiteNotePad,
//   imageTitle: "Bloco de Notas Capa Dura Branca P",
//   cardLabel: "400 Pontos",
// },
// {
//   image: RoutesItemPremiumPen,
//   imageTitle: "Caneta Metal Premium",
//   cardLabel: "400 Pontos",
// },
// {
//   image: RoutesItemNotePadWithPen,
//   imageTitle: "Bloco de Notas com Caneta",
//   cardLabel: "140 Pontos",
// },
